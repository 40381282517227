<template>
  <div>
      <form-wizard ref="transferWizardIndex" color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Kaydet" back-button-text="Geri" class="mb-3 form-wizard">
          <!-- :before-change="validationForm" -->
          <tab-content :title="$t('Choose Product')">
              <section id="knowledge-base-content">
                  <b-row class="kb-search-content-info match-height pt-0 mt-0">
                      <b-col v-for="product in trainersProductsForSale" md="4" sm="6" class="kb-search-content py-1 col-md-4 col-sm-6">
                          <b-card img-alt="Profile Cover Photo" img-top class="card-profile py-3 m-0 shadow mt-1 product-custom-card custom-shadow-3" shadow>
                              <div class="profile-image-wrapper">
                                  <div class="profile-image p-0">
                                      <b-avatar size="120" variant="light" :src="product.image" />
                                  </div>
                              </div>
                              <h4 class="mt-0">{{ product.product_name }}</h4>
                              <div class="custom-flag">
                                  <p class="font-small-4 my-0 py-0 custom-space">
                                      <b>
                                          <b-media-aside class="m-0 d-inline margin-custom">
                                              <b-avatar rounded variant="light-primary" size="30">
                                                  <feather-icon icon="UserIcon" size="20" />
                                              </b-avatar>
                                          </b-media-aside>
                                          {{ $t('Trainer') }} :
                                      </b>{{product.trainer_name }}
                                  </p>
                              </div>
                              <div class="custom-flag">
                                  <p class="font-small-4 my-0 py-0 custom-space">
                                      <b-media-aside class="m-0 d-inline margin-custom">
                                          <b-avatar rounded variant="light-primary" size="30">
                                              <feather-icon icon="AwardIcon" size="20" />
                                          </b-avatar>
                                      </b-media-aside>
                                      <b>{{ $t('Activity') }} : </b>{{product.activity_name }}
                                  </p>
                              </div>
                              <div class="custom-flag">
                                  <p class="font-small-4 my-0 py-0 custom-space">
                                      <b-media-aside class="margin-custom d-inline">
                                          <b-avatar rounded variant="light-primary" size="30">
                                              <feather-icon icon="WatchIcon" size="20" />
                                          </b-avatar>
                                      </b-media-aside>
                                      <b>{{ $t('Activity Time') }} : </b>{{product.activity_time }} dk
                                  </p>
                              </div>
                              <!-- follower projects rank -->
                              <div class="custom-flag">
                                  <p class="font-small-4 custom-space">
                                      <b-media-aside class="m-0 d-inline margin-custom">
                                          <b-avatar rounded variant="light-primary" size="30">
                                              <feather-icon icon="InfoIcon" size="20" />
                                          </b-avatar>
                                      </b-media-aside>
                                      <b>{{ $t('Description') }} : </b> {{ product.detail }}
                                  </p>
                              </div>
                              <!--/ follower projects rank -->
                          </b-card>
                          <b-button @click="selectProduct( product.trainer_product_id, product.trainer_id, product.activity_id)" block variant="primary" class="p-2 select-button">
                              {{ $t('Select') }}
                          </b-button>
                      </b-col>
                  </b-row>
              </section>
          </tab-content>
          <!-- accoint details tab -->
          <tab-content :title="$t('Payment')" v-if="trainersProductsForSale.length !== 0">
              <div class="row">
                  <div class="col-md-6 col-sm-12 product-detail-col">
                      <div class="checkout-items">
                          <b-card class="ecommerce-card custom-glass-card-image-banner" no-body>
                              <!-- Product Image -->
                              <div>
                                  <div class="rounded-top text-center">
                                      <b-img :src="selectedProduct.image" :alt="selectedProduct.image" height="200" class="product-image-custom" />
                                  </div>
                              </div>
                              <!-- Product Details: Card Body -->
                              <b-card-body>
                                  <div class="row">
                                      <div class="col-md-6 col-sm-12">
                                          <b-media no-body>
                                              <b-media-aside class="mr-1">
                                                  <b-avatar rounded variant="light-primary" size="40">
                                                      <feather-icon icon="ItalicIcon" size="25" />
                                                  </b-avatar>
                                              </b-media-aside>
                                              <b-media-body>
                                                  <h5 class="mb-0 text-primary">
                                                      {{ $t('Product Title') }}
                                                  </h5>
                                                  <h5>{{ selectedProduct.activity_name }}</h5>
                                              </b-media-body>
                                          </b-media>
                                          <b-media no-body class="mt-1">
                                              <b-media-aside class="mr-1">
                                                  <b-avatar rounded variant="light-primary" size="40">
                                                      <feather-icon icon="AlignCenterIcon" size="25" />
                                                  </b-avatar>
                                              </b-media-aside>
                                              <b-media-body>
                                                  <h5 class="mb-0 text-primary">
                                                      {{ $t('Product Description') }}
                                                  </h5>
                                                  <h5>{{ selectedProduct.detail }}</h5>
                                              </b-media-body>
                                          </b-media>

                                          <b-media no-body class="mt-1">
                                              <b-media-aside class="mr-1">
                                                  <b-avatar rounded variant="light-primary" size="40">
                                                      <feather-icon icon="UserIcon" size="25" />
                                                  </b-avatar>
                                              </b-media-aside>
                                              <b-media-body>
                                                  <h5 class="mb-0 text-primary">
                                                      {{ $t('Trainer') }}
                                                  </h5>
                                                  <h5>{{ selectedProduct.trainer_name }}</h5>
                                              </b-media-body>
                                          </b-media>
                                          <b-media no-body class="mt-1">
                                              <b-media-aside class="mr-1">
                                                  <b-avatar rounded variant="light-primary" size="40">
                                                      <feather-icon icon="WatchIcon" size="25" />
                                                  </b-avatar>
                                              </b-media-aside>
                                              <b-media-body>
                                                  <h5 class="mb-0 text-primary">
                                                      {{ $t('Activity Name') }}
                                                  </h5>
                                                  <h5>{{ selectedProduct.activity_name }}</h5>
                                              </b-media-body>
                                          </b-media>
                                      </div>
                                  </div>
                              </b-card-body>
                          </b-card>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm-12 options-col">
                      <div class="checkout-options custom-options-card">
                          <b-card>                           
                              <div class="price-details">
                                  <h4 class="price-title text-primary">{{ $t('Price Details') }}</h4>
                                  <hr />
                                  <div class="d-block price-session">
                                      <b-form-group>
                                          <label for="">
                                              <h4 class="text-primary">
                                                  {{ $t('Unit Price') }}
                                              </h4>
                                          </label>
                                          <div class="detail-amt font-weight-bolder"><h4 class="font-weight-bolder">{{selectedProduct.price}}₺</h4>  </div>

                                      </b-form-group>
                                      <b-form-group>
                                          <label for="">
                                              <h4 class="text-primary"> {{ $t('Session Count') }}</h4>
                                          </label>

                                          <template v-if="priceSesionType > 0">
                                              <div class="item-quantity">
                                                  <b-form-spinbutton class="custom-spin" disabled v-model="selectedProduct.price_sesion" size="sm" inline />
                                              </div>
                                          </template>
                                          <template v-else>
                                              <div class="item-quantity">
                                               <b-form-spinbutton class="custom-spin font-weight-bolder" v-model="selectedProduct.price_sesion" size="sm" inline />  
                                              </div>
                                          </template>
                                      </b-form-group>
                                      <hr />
                                      <b-form-group>
                                          <label for="">
                                              <h4 class="text-primary">
                                                  {{ $t('Total Price Payable')  }} 
                                              </h4>
                                          </label>
                                          <div class="detail-amt"> <h4 class="font-weight-bolder">{{totalPrice}}₺</h4>  </div>
                                      </b-form-group>
                                  </div>
                                  <b-button block type="submit" variant="success" @click="buyProduct()">
                                      <feather-icon icon="ShoppingCartIcon" size="20" />
                                      <span class="buy-product-title">{{ $t('Buy Product') }}</span>
                                  </b-button>
                              </div>
                          </b-card>
                      </div>
                  </div>
              </div>
              <form-wizard ref="refFormWizard" color="#7367F0" :title="null" :subtitle="null" finish-button-text="Submit" back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent" v-if="false">
                  <!-- account detail tab -->
                  <tab-content title="Cart" icon="feather icon-shopping-cart">
                      <e-commerce-checkout-step-cart @next-step="formWizardNextStep" />
                  </tab-content>

                  <!-- address -->
                  <tab-content title="Address" icon="feather icon-home">
                      <e-commerce-checkout-step-address :address-details="checkoutDetails.address" @next-step="formWizardNextStep" />
                  </tab-content>

                  <!-- social link -->
                  <tab-content title="Payment" icon="feather icon-credit-card">
                      <e-commerce-checkout-step-payment :payment-details="checkoutDetails.payment" @next-step="formWizardNextStep" />
                  </tab-content>
              </form-wizard>
          </tab-content>
          <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left mt-2">
                  <b-button v-if="props.activeTabIndex === 1" type="submit" @click="backProductList()" variant="primary">
                      <feather-icon icon="ArrowLeftIcon" size="20" />
                      {{ $t('Back To Product List') }}
                  </b-button>
              </div>
          </template>



      </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import { turkey } from 'flatpickr/dist/l10n/tr.js'
import MoneyFormat from '@/functions/MoneyFormat'
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardCode,
  BCardText,
  BCol,
  BCollapse,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSpinbutton,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BVToastPlugin,
  VBToggle
} from 'bootstrap-vue'

export default {
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: '',
        mobile: '',
        houseNo: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        addressType: null,
      },
      payment: {
        cvv: '',
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
    }
  },
  components: {
    BCardBody,
    BLink,
    BBadge,
    BAvatar,
    BVToastPlugin,
    FormWizard,
    TabContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormSelect,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardText,
    BCardCode,
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    flatPickr,
    BMediaAside,
    BAvatarGroup,
    BMediaBody,

    FormWizard,
    TabContent,

    BFormSpinbutton,
    BCollapse,
    VBToggle
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  data() {
    return {
      disableCoupon: false,
      placeholder: 'dd.mm.YYYY',
      // min_date: "1940-01-01",
      // max_date: "2009-12-31",
      companyBranchId: '',
      updateInsertType: 0,
      formatted: '',
      selected: '',
        gender: 3,



      personData: {
        birthday: '',
        phone_number: '',
        gender: 3,
        email: '',
      },
      maleColor: 'outline-primary',
      femaleColor: 'outline-primary',
      config: {
        dateFormat: 'd.m.Y',
        locale: turkey,
        maxDate: '12.12.2009',
        minDate: '01.01.1940',
      },
      block: {
        blocks: [25],
        uppercase: false,
      },
      isNext: false,
      isFinal: false,
      knowledgeBaseSearchQuery: '',
      kb: [],

       trainersProductsForSale:[],
  
      productBaseData: [],
      productData: [],
      selectedTrainer: {
        trainer_id: '00000000-0000-0000-0000-000000000000',
        full_name: this.$t('Choose Trainer...'),
      },
      selectedActivity: {
        activity_id: '00000000-0000-0000-0000-000000000000',
        name: this.$t('Choose Activity...'),
      },
      optionsTrainer: [],
      optionsActivity: [],
      paymentTypes:{},
      filter: '',
      selectedProductId: null,

      selectedProduct: {},

      quantity: 1,
      totalPrice:0,
      couponCode: null,
      couponDiscount: 0,
      productLoaded: false,
      paramCustomerPersonId: null,
      priceSesionType: 0,
      recommendExpiredDate: null
    }
  },
  watch: {
      'selectedProduct.price_sesion'()
      {
          this.quantity = this.selectedProduct.price_sesion

          if (this.priceSesionType === 0)
          {
              this.totalPrice = this.quantity * this.selectedProduct.price
          }
          else
          {
              //toplam fiyatlı ürün
              this.totalPrice = this.selectedProduct.price
          }
      },
  },
  methods: {
    validationForm() {
      // console.log(this.$refs.transferWizardIndex.activeTabIndex);
      // console.log(this.selectedProductId);
      if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
        //ilk adım
        if (this.selectedProductId !== null) {
          // console.log("next tab");
          this.$refs.transferWizardIndex.nextTab()
        } else {
          this.$functions.Messages.error('No Product Selected!')
        }
      } else {
        //final adımı
        if (
            this.$v.personData.name.required &&
            this.$v.personData.last_name.required &&
            this.$v.personData.phone_number.check &&
            this.$v.personData.email.required
        ) {
          //eğer valid doğrulanırsa
         // this.createCustomer() //customer oluştur
        } else {
          //doğrulanamadı
          this.isFinal = true //save basınca uyarıları tetikleyecek
        }
      }
    },

    getPaymentTypes()
    {
        var conditionTypeId="e377a568-38c8-4155-b455-f96f46aef604"
      this.$database.ConditionService.getConditionsByConditionTypes(conditionTypeId)
          .then((res) =>  {
            var newObject={};
            // var value= [{"ConditionId":"82b097c9-5172-47ba-8754-97be299eb042","ConditionName":"Kredi Kartı"},{"ConditionId":"e3409ab8-3535-4966-a13a-1aca53f269b7","ConditionName":"Nakit"}] //res.Result;
             var value=  JSON.parse(res.Result);
             value.forEach(element=>
             {
              // console.log("element", element);
               var objValues=Object.values(element);
              // console.log("objValue: ", objValues);
               newObject[objValues[0]]=objValues[1];
             });
            // console.log("newObject: ", newObject);
             this.paymentTypes= newObject;
          })
    },

     selectProduct(trainer_product_id) {

      this.selectedProductId = trainer_product_id
   
         var newArray = this.trainersProductsForSale.filter(function (el)
         {
             return el.trainer_product_id == trainer_product_id
         });

         this.selectedProduct = newArray[0];

         if (this.selectedProduct.price_sesion > 1) {
             this.priceSesionType = 1; // grup activite
         }
         else {
             this.priceSesionType = 0; // grup activite
         }
      this.$refs.transferWizardIndex.nextTab()
    },
      buyProduct()
      {

      console.log(this.selectedProduct);

          //console.log(
          //    { "ProductPriceId": this.selectedProduct.product_price_id },
          //    { "PersonId": this.$store.getters['auth/userInfo'].person_id },
          //    { "Quantity": this.quantity })

                this.$swal({
                title: this.$t('Confirm Purchase'),
                text:
                    this.selectedProduct.activity_name +
                    ' ürününü satın almayı onaylıyor musunuz?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: this.$t('Buy Product'),
                cancelButtonText: this.$t('Cancel'),
                customClass: {
                  confirmButton: 'btn btn-outline-success mr-1',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
                  .then((result) => {
                    if (result.value) {

                        this.$database.CustomerProductSaleService.CreateProductSale({
                          ProductPriceId: this.selectedProduct.product_price_id,
                          PersonId: this.$store.getters['auth/userInfo'].person_id,
                          Quantity: this.quantity,
                          PaymentTypeId: 'e3409ab8-3535-4966-a13a-1aca53f269b7'// Kredi kartı yada peşin  Condition Tablosundan

                        })
                            .then((res) => {
                              if (res.IsSuccess === true || res.IsSuccess === '1') {
                                //Urun satış için Token üretme işi başarılı ise
                                //Token i yonlendir.
                                window.location.href =res.Result; //"https://google.com/contact";
                              }
                              else
                              {
                                this.$functions.Messages.warning(res.Result)
                              }
                            })

                    } else {

                      this.$functions.Messages.error('İşlem iptal edildi')
                    }
                  })
    },
    backProductList() {
      this.$refs.transferWizardIndex.prevTab()
      this.$refs.transferWizardIndex.activeTabIndex === 0
    },
        //trainer_id ve company_branch_id ile planlanmıs time table activitylerini alicam
      getTrainersProductsForSale(company_branch_id, trainer_id, activity_id) {
          //console.log({"companyBranchId = ": this.companyBranchId},
          //    { "traineId = ": trainer_id },
          //    { "activity_id": activity_id })
        this.$database.ShoppingService.get_trainers_products_for_sale(company_branch_id, trainer_id, activity_id)
            .then((res) => {
                if (res.IsSuccess === true || res.IsSuccess === 1) {
                    res.Result.map((el) =>
                    {
                        this.trainersProductsForSale.push({
                            activity_id: el.ActivityId,
                            activity_name: el.ActivityName,
                            activity_time: el.ActivityTime,
                            company_branch_id: el.CompanyBranchId,
                            detail: el.Detail,
                            email: el.Email,
                            expiry_day: el.ExpiryDay,
                            expiry_month: el.ExpiryMonth,
                            image: el.Image,
                            participants_count: el.ParticipantsCount,
                            person_id: el.PersonId,
                            price: el.Price,
                            product_image_id: el.ProductImageId,
                            product_name: el.ProductName,
                            product_price_id: el.ProductPriceId,
                            trainer_id: el.TrainerId,
                            trainer_name: el.TrainerName,
                            trainer_product_id: el.TrainerProductId,
                            price_sesion: el.PriceSesion
                        })
                    });
                    //  console.log(this.trainersProductsForSale);
                }
            })
    },
},
computed: {
    nextStep: function () {
    return (
        this.$v.personData.email.required && this.$v.personData.email.email
    )
    },
    selectedProductAmountPriceSesOne() {
        return MoneyFormat(
          (this.selectedProduct.priceInfo.price *
            this.selectedProduct.priceInfo.session) - this.couponDiscount
    )
    },
    selectedProductAmountPriceSesMultiple() {
        return MoneyFormat(this.selectedProduct.priceInfo.price - this.couponDiscount)
    },
    selectedProductPrice() {
        return MoneyFormat(this.selectedProduct.priceInfo.price)
    },
  },
  validations: {},
  created() {
    // this.studio_id = this.$route.params.studioID
    this.companyBranchId =
          this.$store.getters['auth/userInfo'].company_branch_id
      this.getTrainersProductsForSale(this.companyBranchId, '00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000' );
  },
}
</script>

<!-- stillendirme -->
<style scoped src="@/styles/css/customerShopping.css"></style>
<style lang="scss" src="@/styles/scss/customerShopping.scss"></style>
